import * as React from "react";
import Title, { TitleVariant } from "../Title";

interface MicroSection {
  title: string;
}

const MicroSection: React.FC<MicroSection> = ({ title, children }) => (
  <div className="py-4 mt-secondary">
    <Title variant={TitleVariant.h4} text={title} className="text-center" />
    <p className="mt-main">{children}</p>
  </div>
);

export default MicroSection;
