import * as React from "react";
import Button, { ButtonVariant } from "../../components/Button";
import Layout from "../../components/Layout";
import { useLayout } from "../../components/Layout/layout-context";
import MicroSection from "../../components/MicroSection";
import Title, { TitleVariant } from "../../components/Title";

const ExperienceClient = () => {
  const [setShowModal] = useLayout();

  return (
    <Layout
      title="Nos formations en expérience client"
      metaTitle="Formez-vous à l’expérience client"
      description="Créez un discours de marque impactant et optimiser votre site web grâce à nos formations en expérience client"
    >
      <Title variant={TitleVariant.h2} text="Programme" className="mt-main" />
      <Title
        variant={TitleVariant.h3}
        text="Mettez l'expérience client au coeur de votre stratégie e-commerce"
        className="mt-secondary"
      />

      <p className="mt-secondary">
        Découvrez toutes nos formations sur l'expérience client :
      </p>

      <MicroSection title="🍩 Les bases de l'expérience client">
        Prendre en compte vos utilisateurs dans la conception de votre site est
        le meilleur moyen d'accroître votre business. Ces notions sont à la
        portée de tous et vous permettons de débuter une nouvelle phase dans la
        vie de votre e-commerce.
      </MicroSection>
      <MicroSection title="🍩 Créer un discours de marque">
        L'UX writing vous permet d'ajouter de la cohérence dans l'ensemble de
        vos communications et sur votre site e-commerce.
      </MicroSection>
      <MicroSection title="🍩 Adapter l'architecture de l'information et la navigation">
        L'organisation des éléments de votre page ont une importance cruciale
        pour offrir la bonne information au bon moment. Vous serez capable
        d'appliquer cela à votre site pour améliorer le parcours de vos
        utilisateurs et augmenter votre taux de transformation.
      </MicroSection>
      <MicroSection title="🍩 Améliorer l'accessibilité de son site">
        Près de 20% de la population est en situation de handicap grave ou
        léger. C'est donc un client sur cinq qui n'arrivera pas à utiliser votre
        site. Apprenez plusieurs notions très simples à mettre en place pour
        leur permettre de profiter de votre site et passer à l'achat.
      </MicroSection>
      <MicroSection title="🍩 Optimiser vos pages principales (page produit, tunnel d'achat, accueil...) grâce aux connaissances en psychologie">
        🍩 Optimiser vos pages principales (page produit, tunnel d'achat,
        accueil...) grâce aux connaissances en psychologie
      </MicroSection>
      <p className="mt-secondary">
        Contactez-nous en précisant la formation qui vous intéresse !
      </p>
      <div className="flex flex-col items-center justify-center mt-secondary lg:flex-row">
        <Button
          variant={ButtonVariant.primary}
          text="Former mon équipe"
          className=""
          onClick={() => setShowModal(true)}
        />
        <Button
          variant={ButtonVariant.secondary}
          text="Retour aux formations"
          className="mt-6 lg:mt-0 lg:ml-10"
          to="/formations"
        />
      </div>
    </Layout>
  );
};

export default ExperienceClient;
